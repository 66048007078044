export function header() {
    // const main = document.querySelector('main');

    // const headerHeight = header.getBoundingClientRect().height;
    // if(main) main.style.marginTop = `-${headerHeight}px`;

    const header = document.querySelector('header');

    if(header) {

        const stickyHeader = document.getElementById('sticky-header');
        const stickyNav = document.getElementById('page-nav-sticky');
    
        const headerSection = header.querySelector('.header-section');

        let prevScrollPos = window.pageYOffset;
        window.onscroll = function() {
            const currentScrollPos = window.pageYOffset;

            if (prevScrollPos > currentScrollPos) {
                if(stickyHeader) stickyHeader.classList.remove('sticky-hidden');
                if (stickyNav) stickyNav.classList.remove('sticky-hidden');
            } else {
                if(stickyHeader) stickyHeader.classList.add('sticky-hidden');
                if (stickyNav) stickyNav.classList.add('sticky-hidden');
            }

            prevScrollPos = currentScrollPos;
        }

        function docOverflowHidden() {
            document.documentElement.style.overflow = "hidden";
            stickyHeader.style.position = "fixed";
            stickyHeader.classList.remove('sticky-hidden');
        }
        function docOverflowRemove() {
            document.documentElement.style.removeProperty('overflow');
            stickyHeader.style.position = "sticky";
        }

        const inset = document.querySelector('.__inset');
        const openMenuBtn = document.querySelectorAll('.open-some-menu');
        const allMenus = document.querySelectorAll('.some-menu');
        const headerNav = document.getElementById('header__nav');

        openMenuBtn.forEach((el) => {
            const getDataOpen = el.getAttribute('data-open')
            el.addEventListener('click',() => {

                allMenus.forEach((e) => {
                    e.style.maxHeight = '0';
                    e.style.opacity = 0;
                })
                

                if (el.classList.contains('active')) {
                    docOverflowRemove()
                    headerNav.classList.remove('non-active');
                    inset.classList.remove('show');
                    el.classList.remove('active');
                } else {
                    docOverflowHidden()
                    headerNav.classList.add('non-active');
                    inset.classList.add('show');
                    allMenus.forEach((evt) => {
                        const getMenuData = evt.getAttribute('data-menu');
                        if(getMenuData == getDataOpen) {
                            evt.style.maxHeight = evt.scrollHeight * 2 + "px";
                            evt.style.opacity = 1;
                        }
                    })

                    openMenuBtn.forEach((e) => {
                        e.classList.remove('active');
                    })
                    el.classList.add('active');
                }
            })
        });

        const headerDropdown = document.querySelectorAll('.header-section__dropdown-menu');
        const headerHeight = headerSection.scrollHeight;
        
        if(headerDropdown.length > 0) {
            headerDropdown.forEach(dropdown=>{
                if(window.innerWidth > 768) {
                    dropdown.addEventListener('mouseenter',()=> {
                        const ul = dropdown.querySelector('ul');
        
                        headerDropdown.forEach(el=>{
                            const span = el.querySelector('span');
                            if(dropdown.querySelector('span') !== span){
                                span.classList.add('subdued');
                            } else {
                                span.classList.remove('subdued');
                            }
                        });
        
                        if(ul) {
                            setTimeout(() => {
                                const ulRect = ul.getBoundingClientRect();
                                header.querySelector('.header-section').style.height = `calc(${headerHeight}px + ${ulRect.height}px)`;
                            }, 300);
                            setTimeout(() => {
                                ul.style.opacity = '1'; 
                            }, 350);
                        };
                    });
                    dropdown.addEventListener('mouseleave',()=> {
                        header.querySelector('.header-section').style.height = null;
                        headerDropdown.forEach(dropdown=>{
                            dropdown.querySelector('span').classList.remove('subdued');
                            if(dropdown.querySelector('ul')) {
                                dropdown.querySelector('ul').style.opacity = null;
                            }
                        }); 
                    });
                } else {
                    const span = dropdown.querySelector('span');
                    const link = span.querySelector('.link');
                    const ul = dropdown.querySelector('ul');
                    if (ul) link.removeAttribute('href'); 
                    span.addEventListener('click',(e)=>{

                        insetShow()

                        headerDropdown.forEach(notShown=>{
                            const notShownList = notShown.querySelector('ul');
                            if(notShownList && notShownList !== ul) {
                                notShownList.style.opacity = null;
                                setTimeout(() => {
                                    notShownList.style.display = null;
                                }, 300);
                            }
                        })
        
                        if(ul) {
                            const ulRect = ul.getBoundingClientRect();
                            header.querySelector('.header-section').style.height = `calc(${headerHeight}px + ${ulRect.height}px)`;
                            ul.style.display = 'flex';
                            setTimeout(() => {
                                ul.style.opacity = '1';
                            }, 50);
                        } else {
                            header.querySelector('.header-section').style.height = `${headerHeight}px`;
                        };
                    });
                };
            });
        }

        if(inset) {
            inset.addEventListener('click',()=>{
                insetClick()
            })
        }
    }

};

function insetShow() {
    const inset = document.querySelector('.__inset');
    document.documentElement.style.overflow = "hidden";
    if(!inset.classList.contains('show')){
        inset.classList.add('show');
    };
}

function insetClick() {
    const inset = document.querySelector('.__inset');

    const headerMain = document.querySelector('header');
    headerMain.querySelector('.header-section').style.height = null;
    const headerDropdown = document.querySelectorAll('.header-section__dropdown-menu');
    if(headerDropdown) {
        headerDropdown.forEach(el=>{
            const span = el.querySelector('span');
            if(span.parentNode.querySelector('ul')) {
                span.parentNode.querySelector('ul').style.opacity = null;
                setTimeout(() => {
                    span.parentNode.querySelector('ul').style.display = 'none';
                }, 300);
            };
            span.classList.remove('subdued');
        });
    };

    document.documentElement.style.overflow = null;
    inset.classList.remove('show');
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { header } from "./путь/к/файлу/header.js";

// Активация: header();