import { debounce, getData, sendData } from "./utils.js";

export function feedBack() {
    const priceCalcFormBtns = document.querySelector('.price-calc-form-buttons');
    if(priceCalcFormBtns) {
        const href = new URL(window.location.href);
        let area = href.searchParams.get('area');
        let room = href.searchParams.get('room');

        const buttons = priceCalcFormBtns.querySelectorAll('input');
        const rangeInput = document.querySelector('.range-input');
        buttons.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const updatedInputVal = updateInput(btn);
                rangeInput.value = updatedInputVal;

                const event = new Event('input', {
                    bubbles: true,
                    cancelable: true,
                });
                rangeInput.dispatchEvent(event);
            });

            if(room && room == btn.id) {
                btn.click()
            };
        });
        rangeInput.addEventListener('input',()=>{
            const updatedButton = updateButtons(rangeInput);
            const btnWithId = document.getElementById(`${updatedButton}`);
            btnWithId.checked = true;
        });

        if(area) {
            rangeInput.value = area;
            const event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });
            rangeInput.dispatchEvent(event);
        }
    };
    const feedBackForm = document.getElementById('feedback-form');
    if(feedBackForm) {
        const selectorService = document.querySelector('div[data-field="service"]');

        if(selectorService) {
            const href = new URL(window.location.href);
            let id = href.searchParams.get('id');

            selectorService.querySelectorAll('.__select__input').forEach(input=>{
                if(id && input.id == `serviceSelect${id}`) {
                    const label = document.querySelector(`label[for="serviceSelect${id}"]`);
                    input.checked = true;
                    selectorService.querySelector('.__select__title').innerText = label.innerText;
                }

                input.addEventListener('click',()=>{
                    const dataID = input.getAttribute('data-id');
                    getData(`/api/services/${dataID }`)
                        .then(res=>{
                            createSelectsTariffs(res);
                        })

                    debouncedCalcPrice()
                });

                setTimeout(() => {
                    if(input.checked) input.click();
                }, 300);
            });

            const rangeInput = feedBackForm.querySelector('.range-input');
            rangeInput.addEventListener('input',()=>{
                debouncedCalcPrice()
            });

            // dinamicCalcPrice()
        }
    };
    const quizForm = document.getElementById('quiz-form');
    if(quizForm) {
        const selectorService = document.querySelector('div[data-field="service"]');
        selectorService.querySelectorAll('.__select__input').forEach(input=>{
            input.addEventListener('click',()=>{
                const dataID = input.getAttribute('data-id');
                getData(`/api/services/${dataID }`)
                    .then(res=>{
                        createSelectsTariffs(res);
                    })

                setTimeout(() => {
                    debouncedCalcPrice()
                }, 300);
            })

            if(input.checked) input.click()
        })

        const rangeInput = quizForm.querySelector('.range-input');
        rangeInput.addEventListener('input',()=>{
            debouncedCalcPrice()
        });
        const event = new Event('input', {
            bubbles: true,
            cancelable: true,
        });
        rangeInput.dispatchEvent(event);
    };
    const orderEdit = document.getElementById('order-edit-form');
    if(orderEdit) {
        const selectorService = document.querySelector('div[data-field="service"]');
        selectorService.querySelectorAll('.__select__input').forEach(input=>{
            input.addEventListener('click',()=>{
                const dataID = input.getAttribute('data-id');
                getData(`/api/services/${dataID }`)
                    .then(res=>{
                        createSelectsTariffs(res);
                    })

                setTimeout(() => {
                    debouncedCalcPrice()
                }, 300);
            })

            if(input.checked) input.click()
        })

        const rangeInput = orderEdit.querySelector('.range-input');
        rangeInput.addEventListener('input',()=>{
            debouncedCalcPrice()
        });
        const event = new Event('input', {
            bubbles: true,
            cancelable: true,
        });
        rangeInput.dispatchEvent(event);
    }
    const jobResponseForm = document.getElementById('job-response-form');
    if(jobResponseForm) {
        const vacancy = document.querySelector('div[data-field="vacancy"]');

        if(vacancy) {
            const href = new URL(window.location.href);
            let id = href.searchParams.get('id');
            const selectedService = document.getElementById(`vacancySelect${id}`);
            const label = document.querySelector(`label[for="vacancySelect${id}"]`)

            selectedService.checked = true;
            vacancy.querySelector('.__select__title').innerText = label.innerText;
        }
    };
}

const debouncedCalcPrice = debounce(dinamicCalcPrice, 300); 

function dinamicCalcPrice() {
    const priceMenu = document.querySelector('.feedback-form-section__menu');
    if(priceMenu) {
        const span = priceMenu.querySelector('span');

        const serviceSelector = document.querySelector('div[data-field="service"]');
        const tariffSelector = document.querySelector('div[data-field="tariff"]');
        const inputRange = document.querySelector('.range-input');
        
        let body = {}

        body.service = getCheckedInputId(serviceSelector);
        body.tariff = getCheckedInputId(tariffSelector);
        body.area = Number(inputRange.value);

        sendData(body, '/api/calculators/order/')
            .then(res=>{
                span.innerText = res.total_cost;
            });

    }
}

function getCheckedInputId(selector) {
    let id = '';
    selector.querySelectorAll('.__select__input').forEach(input=>{
        if(input.checked) id = input.getAttribute('data-id');
    });

    return Number(id);
}

function createSelectsTariffs(res) {
    const tariffs = res.tariffs;
    const tariffSelect = document.querySelector('div[data-field="tariff"]');
    const selectContent = tariffSelect.querySelector('.__select__content');
    const selectTitle = tariffSelect.querySelector('.__select__title');

    selectTitle.textContent = '';
    selectContent.innerHTML = '';
    if(tariffs.length > 0) {
        const fragment = document.createDocumentFragment();

        tariffs.forEach(tariff=>{
            const input = document.createElement('input');
            const label = document.createElement('label');

            input.id =`tariffSelect${tariff.id}`;
            input.classList.add('__select__input');
            input.setAttribute('data-id', tariff.id);
            input.type ="radio";
            input.name ="tariffSelect";

            label.setAttribute('for', `tariffSelect${tariff.id}`);
            label.classList.add('__select__label');
            label.innerText = `${tariff.name}`;

            fragment.appendChild(input);
            fragment.appendChild(label);
        })

        selectContent.appendChild(fragment);


        const selectSingle_title = tariffSelect.querySelector('.__select__title');
        const selectSingle_labels = tariffSelect.querySelectorAll('.__select__label');
        for (let i = 0; i < selectSingle_labels.length; i++) {
            selectSingle_labels[i].addEventListener('click', (evt) => {
                selectSingle_title.textContent = evt.target.textContent;
                tariffSelect.setAttribute('data-state', '');
                dinamicCalcPrice()
            });
        }
        const selectSingle_input = tariffSelect.querySelector('.__select__input');
        const selectSingle_label = tariffSelect.querySelector(`label[for="${selectSingle_input.id}"]`);
        selectSingle_title.textContent = selectSingle_label.textContent;
        selectSingle_input.checked = true;
    }
}

function updateButtons(input) {
    let buttonToUpdate = '';
    const value = input.value;
    const parsedVal = parseFloat(value);
    switch (true) {
        case (parsedVal >= 0 && parsedVal <= 25):
            buttonToUpdate = 'studio';
            break;
        case (parsedVal > 25 && parsedVal <= 50):
            buttonToUpdate = 'one';
            break;
        case (parsedVal > 50 && parsedVal <= 75):
            buttonToUpdate = 'two';
            break;
        case (parsedVal > 75 && parsedVal <= 100):
            buttonToUpdate = 'three';
            break;
        case (parsedVal > 100 && parsedVal <= 125):
            buttonToUpdate = 'four';
            break;
        case (parsedVal > 125 && parsedVal <= 150):
            buttonToUpdate = 'five';
            break;
    }

    return buttonToUpdate;
}

function updateInput(btn) {
    let updatedValue = 1;

    switch (true) {
        case (btn.id == 'studio'):
            updatedValue = 1;
            break;
        case (btn.id == 'one'):
            updatedValue = 26;
            break;
        case (btn.id == 'two'):
            updatedValue = 51;
            break;
        case (btn.id == 'three'):
            updatedValue = 76;
            break;
        case (btn.id == 'four'):
            updatedValue = 101;
            break;
        case (btn.id == 'five'):
            updatedValue = 126;
            break;
    }

    return updatedValue;
}

