import { debounce, findParentWithClass, getCurrentQuery, setNewQuery } from "./utils.js";

const filterElAll = document.querySelectorAll('.filter__el__show-all');

export function filters() {
    const filterElems = document.querySelectorAll('.filter__el');
    if(filterElems.length > 0) {
        const allQueryObj = {};
        filterElems.forEach(filter=>{
            const input = filter.querySelector('input');
            setFiltersState(input);

            const inputName = parseInt(input.getAttribute('name'));
            if(input.classList.contains('many_filters') && getCurrentQuery().category) {
                const categoryValues = getCurrentQuery().category.split(',');
                categoryValues.forEach(value=>{
                    if(parseInt(value) == parseInt(input.id)) {
                        allQueryObj[`${inputName}`] = parseInt(input.id);
                    }
                })
            }
            
            input.addEventListener('click', () => {
                let currentQuery = new URLSearchParams(window.location.search);
                currentQuery.set('page', 1);
    
                if (input.classList.contains('many_filters')) {
                    const allQueryArr = [];
    
                    allQueryObj[`${inputName}`] = parseInt(input.id);
                    Object.values(allQueryObj).forEach(value => {
                        allQueryArr.push(value);
                    });
    
                    setFiltersParentState(input);
    
                    currentQuery.set('category', allQueryArr.join(','));
                } else {
                    currentQuery.set('category', input.id);
                }
    
                window.history.pushState(null, '', `?${currentQuery.toString()}`);
                setNewQuery('category', currentQuery.get('category'));
            });
        });
    }
    if(filterElAll.length > 0) {
        filterElAll.forEach(el=>{
            const input = el.querySelector('input');
            if(input && input.classList.contains('__filter-faq-all')) {
                input.addEventListener('click', function() {
                    clearAllQuery();
                });
            } else if(input) {
                input.addEventListener('click', function() {
                    setNewQuery('category', input.id);
                });
            }
        })
    }
}

const debouncedNewQuery = debounce(setNewQuery, 1000);

let filterAllActive = true;

function clearAllQuery() {
    const url = new URLSearchParams(window.location.search);
    window.location.search = '';
}

function setFiltersState(input) {
    const keysVal = getCurrentQuery();
    if(keysVal.category) {
        const categoryValues = keysVal.category.split(',');
        categoryValues.forEach(value=>{
            if(parseInt(value) == parseInt(input.id)) {
                input.checked = true;
                filterAllActive = false;
                setFiltersParentState(input);
            }
        })
    }
    if(!filterAllActive) filterElAll.forEach(el=>el.classList.add('link_light_subdued'))
}

function setFiltersParentState(input) {
    const filterItem = findParentWithClass(input, 'filter__item');
    if(filterItem) {
        const filterParent = filterItem.querySelector('.filter__item__category');
        filterParent.style.color = '#1B2230';
    }
}
